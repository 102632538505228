<template>
  <span
    :data-id="color"
    class="color-tag"
    :class="{
      [computedColor]: true
    }"
  ></span>
</template>

<script>
export default {
  name: 'ColorTag',
  props: {
    limitColor: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    computedColor: function() {
      if (!this.limitColor) {
        return this.color
      }
      if (this.color == 'yellow' || this.color == 'blue') {
        return this.color
      }
      if (this.color == 'red' || this.color == 'orange') {
        return 'grey'
      }
      return 'transparent'
    }
  }
}
</script>

<style lang="scss" scoped>
.color-tag {
  position: absolute;
  background-color: transparent;
  z-index: 1;

  &.red {
    background-color: #e73e01;
  }

  &.orange {
    background-color: #ff7f00;
  }

  &.green {
    background-color: #01d758;
  }

  &.yellow {
    background-color: #ffe436;
  }

  &.grey {
    background-color: #9c9c9c;
  }

  &.blue {
    background-color: #77b5fe;
  }
}
</style>
